<template lang="pug">
.container
  .main
    slot.main(name="main")
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'BaseLayout'
})
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .inner {
    display: flex;
    height: 100%;

    .sidebar {
      transition: 0.5s;
      min-width: 190px;
      max-width: 190px;
      height: calc(100vh - 64px);
      overflow-y: scroll;
    }

    .sidebar.hide {
      min-width: 68px;
      max-width: 68px;
    }

    .main {
      padding: 24px;
      width: 100%;
      position: relative;
      overflow-y: scroll;
      height: calc(100vh - 112px);
    }
  }
}

</style>