





































import Vue from "vue";

import TheTemplate from "../TheTemplate.vue";
import {
  NewSessionsApiFunc,
  NewVacanciesApiFunc,
  NewTenantsApiFunc,
} from "@/api";
import { TenantWebsite, Group, Place, VacantStatus } from "@/models";
import ToiletGroupContainer from "./components/ToiletGroupContainer.vue";
import FooterLogo from "@/components/FooterLogo.vue";
import Questionnaire from "@/components/Questionnaire.vue";
import { redirectUrlMap } from "@/data/redirectUrlMap";

type DataType = {
  tenantWebsite?: TenantWebsite;
  statuses: { [key: string]: VacantStatus[] };
  canLogout: boolean;
  language: string;
  questionnaireUrl: string;
  redirectUrlMap: { [key: string ]: string };
};

export default Vue.extend({
  name: "TenantWebsiteDetailScreen",

  components: {
    TheTemplate,
    ToiletGroupContainer,
    FooterLogo,
    Questionnaire,
  },

  data(): DataType {
    return {
      tenantWebsite: undefined,
      statuses: {},
      canLogout: false,
      language: "ja_JP",
      questionnaireUrl: "" ,
      redirectUrlMap: redirectUrlMap,
    };
  },

  computed: {
    tenantWebsiteName(): string {
      return this.tenantWebsite?.name || "";
    },

    tenantWebsiteLogoUrl(): string {
      return this.tenantWebsite?.logoUrl || "";
    },

    placeGroups(): Group[] {
      return (this.tenantWebsite?.groups || []).filter(
        (x: Group) => x.places !== undefined && x.places.length > 0
      );
    },
    subtitleName(): string {
       if(this.isTwLanguage()) {
           return "設施使用情況";
       }
       return "施設の空き状況";
    },
    isYurakucho(): boolean {
      if (this.tenantWebsiteName == '有楽町マルイ' || this.tenantWebsiteName == '有楽町マルイ スタッフ様用'){
        return true;
      }
      return false;
    },
    isKitasenju(): boolean {
      if (this.tenantWebsiteName == '北千住マルイ' || this.tenantWebsiteName == '北千住マルイ 従業員用画面'){
        return true;
      }
      return false;
    },
  },

  async mounted() {
    this.getLanguage();
    await this.checkLogin();
    await this.getTenantWebsite().then(() => this.fetchVacancy());
    this.redirect();
    this.setQuestionnaireUrl();
    setInterval(async () => await this.fetchVacancy(), 5000);
  },

  methods: {
    async checkLogin(): Promise<void> {
      // パスコードおよびログインの要否に関する仕様が 最初の実装者に伝わっておらず(なんとか魔改造して)こういうひどい実装になってます...ごめんなさい...
      const websiteCode: string = this.$router.currentRoute.params.websiteCode;
      const { data, err } = await NewTenantsApiFunc().getAuthParameter(
        websiteCode
      );

      if (data === undefined) {
        this.$router.push({ name: "not-found" });
        return;
      }

      if (err != null) {
        this.$handleApiError(err);
        return;
      }
      if (data.userAuth) {
        this.canLogout = true;
      }

      if (!data.passcodeAuth && !data.userAuth) {
        return;
      }

      if (!data.passcodeAuth && data.userAuth && this.$auth.isAuthed()) {
        return;
      }

      if (
        data.passcodeAuth &&
        !data.userAuth &&
        this.$auth.isAuthorizedWebsite(websiteCode)
      ) {
        return;
      }

      if (
        data.passcodeAuth &&
        data.userAuth &&
        this.$auth.isAuthed() &&
        this.$auth.isAuthorizedWebsite(websiteCode)
      ) {
        return;
      }

      this.$auth.clearWithoutWebsite();
      this.$router.push({ name: "login" });
    },

    getStatuses(group: Group): VacantStatus[] {
      return this.statuses[group.groupCode || ""] || [];
    },

    async getTenantWebsite(): Promise<void> {
      const res = await NewVacanciesApiFunc().getTenantWebsite(
        this.$router.currentRoute.params.websiteCode,
        this.language,
      );

      if (res.err != null) {
        this.$handleApiError(res.err, this.getTenantWebsite);
        return;
      }
      this.tenantWebsite = res.data;
    },

    async fetchVacancy(): Promise<void> {
      for (const group of this.placeGroups) {
        const placeIds = (group.places || [])
          .map((place: Place): string => place.id || "")
          .filter((e) => !!e);

        const { data, err } = await NewVacanciesApiFunc().getPlaceStatuses(
          this.$router.currentRoute.params.websiteCode,
          placeIds,
          this.language,
        );

        if (err != null) {
          this.$handleApiError(err, this.getTenantWebsite);
          continue;
        }
        // this.statuses[group.groupCode || ''] = data
        this.$set(this.statuses, group.groupCode || "", data);
      }
    },

    async confirmLogout(): Promise<void> {
      if (confirm("ログアウトします")) {
        await this.logout();
      }
    },

    async logout(): Promise<void> {
      if (this.$error.networkErr) {
        return;
      }

      if (this.$auth.isAuthed()) {
        // id / パスワード不要の website では、accessToken を使ってないためログアウトAPIを呼ばない。
        const { err } = await NewSessionsApiFunc().logout(
          this.$auth.accessToken
        );
        if (err != null) {
          this.$handleApiError(err, this.logout);
          return;
        }
      }
      this.$auth.clear();
      this.$router.push({ name: "login" });
    },
    getLanguage(): void {
      if (this.$router.currentRoute.query.language === undefined) {
          this.language = "ja_JP";
      }
      if (this.$router.currentRoute.query.language === "zh_TW") {
          this.language = this.$router.currentRoute.query.language
      }
    },
    isTwLanguage(): boolean {
      if (this.language === "zh_TW") {
          return true;
      }
      return false;
    },
    setQuestionnaireUrl(): void {
      if (this.tenantWebsiteName == "有楽町マルイ") {
        this.questionnaireUrl = "https://docs.google.com/forms/d/1u_gnb2v9ATpGe46FbqsYs0TIxIqRX85ywjtfpxn4AsM/edit?usp=forms_home&ths=true";
      } else if (this.tenantWebsiteName == "有楽町マルイ スタッフ様用") {
        this.questionnaireUrl = "https://docs.google.com/forms/d/1i70pKNsdDQ6Oe03sut1FO16X5UpCwockOx6nIjou-nM/edit?usp=forms_home&ths=true";
      } else if (this.tenantWebsiteName == "北千住マルイ") {
        this.questionnaireUrl = "https://docs.google.com/forms/d/1Lh1x2dfw8z6EGyIhUaJTN0yIwpy9WUe-QryaodAjcDQ/edit?usp=forms_home&ths=true";
      } else if (this.tenantWebsiteName == "北千住マルイ 従業員用画面") {
        this.questionnaireUrl = "https://docs.google.com/forms/d/1GlbzmABEZc-5ircf2Ie7R2uHbkRLLcp5mfwnJzatWPg/edit?usp=forms_home&ths=true";
      }
    },
    redirect(): void {
      const path = this.$route.path.replace("/","");
      console.log(path);
      if(this.redirectUrlMap[path] === undefined) {
        return;
      }
      window.location.href = this.redirectUrlMap[path];
    }
  },
});
