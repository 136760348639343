






import Vue from 'vue'
import BaseLayout from '@/components/BaseLayout.vue'

export type DataType = {
  hide: boolean;
}

export default Vue.extend({
  name: 'TheTemplate',

  components: {
    BaseLayout
  },

  data(): DataType {
    return {
      hide: false,
    }
  },

  computed: {
    arrowStyleText(): string {
      if (this.hide) {
        return 'transform: rotateZ(90deg);'
      }
      return 'transform: rotateZ(-90deg);'
    }
  },

  methods: {
    toggleHide(): void {
      this.hide = !this.hide
    }
  }
})
