































import Vue, { PropType } from "vue";

import { Group, Place, VacantStatus } from "@/models";
import ToiletListItem from "./ToiletListItem.vue";

type DataType = {
  selecting: boolean;
};

export default Vue.extend({
  name: "ToiletGroupContainer",

  components: {
    ToiletListItem
  },

  props: {
    group: Object as PropType<Group>,
    statuses: Array as PropType<Array<VacantStatus>>,
    language: String
  },

  data(): DataType {
    return {
      selecting: false
    };
  },

  computed: {
    isNoVacant(): boolean {
      return (
        (this.statuses || []).filter(e => e.vacant === 0).length ===
        (this.statuses?.length || 0)
      );
    },

    containerClassText(): string {
      return this.isNoVacant ? "no-vacant" : "has-vacant";
    },

    isOneChild(): boolean {
      return this.statuses?.length === 1;
    },

    cellClassText(): string {
      return this.isOneChild || this.selecting ? "selecting" : "";
    },

    showing(): boolean {
      return this.isOneChild || this.selecting;
    },

    iconName(): string {
      if (this.group.groupCode === "men") {
        return "toiletMan";
      } else if (this.group.groupCode === "women") {
        return "toiletWoman";
      } else if (this.group.groupCode === "accessible") {
        if (this.isTaiwan){
          return "TaiwanAccessible"
        }
        return "toiletAccessible";
      } else if (this.group.groupCode === "unisex") {
        return "toiletUnisex";
      } else if (this.group.groupCode === "nursing_room") {
        return "toiletNursingRoom";
      } else if (this.group.groupCode === "powder_corner") {
        return "toiletPowderCorner";
      }
      return "";
    },

    iconClassName(): string {
      // 日本
      if (this.group.groupCode === "men") {
        return "toilet-group-type-icon-man";
      } else if (this.group.groupCode === "women") {
        return "toilet-group-type-icon-woman";
      } else if (this.group.groupCode === "accessible") {
          if (this.isTaiwan){
            return "toilet-group-type-icon-TaiwanAccessible"
          }
          return "toilet-group-type-icon-accessible";
      } else if (this.group.groupCode === "unisex") {
        return "toilet-group-type-icon-unisex";
      } else if (this.group.groupCode === "nursing_room") {
        return "toilet-group-type-icon-nursing_room";
      } else if (this.group.groupCode === "powder_corner") {
        return "toilet-group-type-icon-powder_corner";
      }
      return "";
    },
    translateWords(): string {
      // ここは台湾語
      if (this.isTaiwan){
        switch(this.group.name) {
          case "WOMEN":
            return "女性";
          case "MEN":
            return "男性";
          case "UNISEX":
            return "性別友善";
          case "ACCESSIBLE":
            return "性別友善・無障礙";
          default:
            return "";
        }
      } else if (this.isMarui){
        switch(this.group.name) {
          case "WOMEN":
            return "女子";
          case "MEN":
            return "男子";
          case "UNISEX":
            return "男女兼用";
          case "ACCESSIBLE":
            return "みんなのトイレ";
          case "NURSING_ROOM":
            return "授乳室";
          case "POWDER_CORNER":
            return "パウダールーム";
          default:
            return "";
        }
      }
      else {
        switch(this.group.name){
          case "WOMEN":
            return "女性";
          case "MEN":
            return "男性";
          case "UNISEX":
            return "男女兼用";
          case "ACCESSIBLE":
            return "バリアフリー";
          case "NURSING_ROOM":
            return "授乳室";
          case "POWDER_CORNER":
            return "パウダーコーナー";
          default:
            return "";
        }
      }
    },
    isTaiwan(): boolean {
      return this.language === "zh_TW" ? true : false;
    },
    isMarui(): boolean {
      if (this.$route.path.match("aa38d7bcad184d") || this.$route.path.match("b95556b45eb44e")　|| this.$route.path.match("7405e102a57742") || this.$route.path.match("1fd8caebcd224e")) {
        return true;
      }
      return false;
    },
  },



  methods: {
    getStatus(p: Place): VacantStatus | undefined {
      return (this.statuses || []).find(e => e.id === p.id);
    },

    toggleShowing(): void {
      this.selecting = !this.selecting;
    }
  }
});
