<template>
  <svg width="158" height="52" viewBox="0 0 158 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M85.138 36.8799L82.2048 42.9326L79.2757 36.8799H76.282L82.2048 49.1199L88.1297 36.8799H85.138Z"
      fill="#969696"
    />
    <path
      d="M92.3891 49.1199L95.3222 43.0672L98.2493 49.1199H101.243L95.3222 36.8799L89.3994 49.1199H92.3891Z"
      fill="#969696"
    />
    <path
      d="M121.444 49.1199L124.373 43.0672L127.302 49.1199H130.296L124.373 36.8799L118.448 49.1199H121.444Z"
      fill="#969696"
    />
    <path
      d="M143.605 36.8799V49.1199L137.242 40.4356V49.1199H134.196V36.8799H143.605Z"
      fill="#969696"
    />
    <path
      d="M107.257 43.1182C107.257 41.3556 108.551 39.8134 110.293 39.8134H115.459C114.391 38.0529 112.483 36.8799 110.295 36.8799C106.946 36.8799 104.237 39.6176 104.237 42.9999C104.237 46.3802 106.948 49.1199 110.295 49.1199C112.483 49.1199 114.393 47.9428 115.461 46.1864H110.295C108.553 46.1864 107.257 44.8787 107.257 43.1182Z"
      fill="#969696"
    />
    <path
      d="M15.7571 50.6478H16.9062V48.8536L16.876 47.9061C17.3497 48.3093 17.8436 48.531 18.3376 48.531C19.5875 48.531 20.7265 47.4424 20.7265 45.5373C20.7265 43.8337 19.9403 42.7149 18.5291 42.7149C17.9142 42.7149 17.3094 43.0576 16.8256 43.4608H16.7953L16.7046 42.856H15.7571V50.6478ZM18.1158 47.5734C17.7731 47.5734 17.3396 47.4424 16.9062 47.0694V44.3579C17.38 43.9043 17.7932 43.6825 18.2267 43.6825C19.154 43.6825 19.527 44.3982 19.527 45.5574C19.527 46.8577 18.9222 47.5734 18.1158 47.5734Z"
      fill="#969696"
    />
    <path
      d="M24.3239 48.531C25.6948 48.531 26.9346 47.4726 26.9346 45.6381C26.9346 43.7833 25.6948 42.7149 24.3239 42.7149C22.953 42.7149 21.7132 43.7833 21.7132 45.6381C21.7132 47.4726 22.953 48.531 24.3239 48.531ZM24.3239 47.5835C23.457 47.5835 22.9127 46.8073 22.9127 45.6381C22.9127 44.4587 23.457 43.6725 24.3239 43.6725C25.2009 43.6725 25.7452 44.4587 25.7452 45.6381C25.7452 46.8073 25.2009 47.5835 24.3239 47.5835Z"
      fill="#969696"
    />
    <path
      d="M29.1361 48.4H30.4767L31.1521 45.6683C31.2932 45.1441 31.394 44.6301 31.5049 44.0555H31.5553C31.6762 44.6301 31.777 45.1341 31.9081 45.6582L32.6137 48.4H34.0047L35.4361 42.856H34.3474L33.6318 45.8598C33.5108 46.3941 33.4201 46.9081 33.3092 47.4323H33.2689C33.1378 46.9081 33.027 46.3941 32.8959 45.8598L32.0996 42.856H31.021L30.2449 45.8598C30.1138 46.384 30.003 46.9081 29.882 47.4323H29.8417C29.7409 46.9081 29.6502 46.3941 29.5393 45.8598L28.8034 42.856H27.6342L29.1361 48.4Z"
      fill="#969696"
    />
    <path
      d="M38.8357 48.531C39.5615 48.531 40.1965 48.2891 40.7106 47.9464L40.3074 47.2105C39.9042 47.4726 39.4808 47.6238 38.9869 47.6238C38.0293 47.6238 37.364 46.9888 37.2733 45.9304H40.8719C40.9021 45.7993 40.9223 45.5675 40.9223 45.3457C40.9223 43.7733 40.136 42.7149 38.6644 42.7149C37.364 42.7149 36.1343 43.8337 36.1343 45.6381C36.1343 47.4625 37.3338 48.531 38.8357 48.531ZM37.2632 45.1341C37.3741 44.1563 37.989 43.6221 38.6845 43.6221C39.4909 43.6221 39.9244 44.1765 39.9244 45.1341H37.2632Z"
      fill="#969696"
    />
    <path
      d="M42.2269 48.4H43.376V44.9425C43.7288 44.0656 44.2732 43.7531 44.7268 43.7531C44.9485 43.7531 45.0896 43.7833 45.2812 43.8438L45.4928 42.8358C45.3215 42.7552 45.14 42.7149 44.8679 42.7149C44.2732 42.7149 43.6885 43.1382 43.2954 43.8539H43.2652L43.1744 42.856H42.2269V48.4Z"
      fill="#969696"
    />
    <path
      d="M48.5515 48.531C49.2773 48.531 49.9123 48.2891 50.4264 47.9464L50.0232 47.2105C49.62 47.4726 49.1966 47.6238 48.7027 47.6238C47.7451 47.6238 47.0798 46.9888 46.9891 45.9304H50.5877C50.6179 45.7993 50.6381 45.5675 50.6381 45.3457C50.6381 43.7733 49.8518 42.7149 48.3801 42.7149C47.0798 42.7149 45.8501 43.8337 45.8501 45.6381C45.8501 47.4625 47.0496 48.531 48.5515 48.531ZM46.979 45.1341C47.0899 44.1563 47.7048 43.6221 48.4003 43.6221C49.2067 43.6221 49.6401 44.1765 49.6401 45.1341H46.979Z"
      fill="#969696"
    />
    <path
      d="M53.8579 48.531C54.4829 48.531 55.0574 48.1984 55.4707 47.775H55.501L55.6018 48.4H56.5493V40.3662H55.3901V42.4225L55.4405 43.3398C54.9869 42.9568 54.5837 42.7149 53.9486 42.7149C52.7088 42.7149 51.5698 43.8337 51.5698 45.6381C51.5698 47.4726 52.4669 48.531 53.8579 48.531ZM54.1301 47.5734C53.2632 47.5734 52.7693 46.8779 52.7693 45.6179C52.7693 44.4184 53.3942 43.6825 54.1704 43.6825C54.5837 43.6825 54.9768 43.8136 55.3901 44.1865V46.8981C54.9869 47.3617 54.5938 47.5734 54.1301 47.5734Z"
      fill="#969696"
    />
    <path
      d="M63.1365 48.531C64.3864 48.531 65.5254 47.4424 65.5254 45.5373C65.5254 43.8337 64.7291 42.7149 63.328 42.7149C62.7434 42.7149 62.1486 43.0273 61.6749 43.4406L61.7051 42.4931V40.3662H60.556V48.4H61.4632L61.5741 47.8254H61.6043C62.0781 48.279 62.6325 48.531 63.1365 48.531ZM62.9147 47.5734C62.572 47.5734 62.1285 47.4424 61.7051 47.0694V44.3579C62.1688 43.9043 62.5922 43.6825 63.0256 43.6825C63.953 43.6825 64.3259 44.3982 64.3259 45.5574C64.3259 46.8577 63.7211 47.5734 62.9147 47.5734Z"
      fill="#969696"
    />
    <path
      d="M71.3118 42.856H70.1829L69.2958 45.7086L68.8624 47.271H68.812C68.6406 46.7569 68.4693 46.2025 68.308 45.7086L67.2798 42.856H66.0904L68.2878 48.3597L68.1769 48.7427C67.9753 49.3374 67.6225 49.7809 67.0177 49.7809C66.8766 49.7809 66.7153 49.7305 66.6145 49.7003L66.3928 50.6075C66.5843 50.6781 66.8061 50.7184 67.0883 50.7184C68.2576 50.7184 68.8422 49.9221 69.2757 48.7326L71.3118 42.856Z"
      fill="#969696"
    />
  </svg>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "FooterLogo",
});
</script>
