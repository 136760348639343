














import Vue, { PropType } from 'vue'

import { VacantStatus } from '@/models'

export default Vue.extend({
  name: 'ToiletListItem',

  components: {
  },

  props: {
    name: String as PropType<string>,
    floor: String as PropType<string>,
    stallsLabel: String as PropType<string>,
    status: Object as PropType<VacantStatus>,
  },

  computed: {
    hasVacant(): boolean {
      return this.vacant > 0
    },

    statusText(): string {
      return this.status?.vacantLabel || ''
    },

    remainLabelText(): string {
      return this.stallsLabel
    },

    total(): number {
      return this.status?.total || 0
    },

    vacant(): number {
      return this.status?.vacant || 0
    },

    remainText(): string {
      return `${this.vacant}/${this.total}`
    },

    cellClassText(): string {
      return this.hasVacant ? 'has-vacant' : 'no-vacant'
    }
  },

  methods: {
  }
})
